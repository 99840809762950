export function useWindowEvent(name, handler) {
    // This is a client-side only hook
    if (!import.meta.client) {
        return;
    }

    onMounted(() => {
        window.addEventListener(name, handler);
    });

    onBeforeUnmount(() => {
        window.removeEventListener(name, handler);
    });
}
